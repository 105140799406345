import config from "../config";

interface Diashow {
  images: string[];
  headline: string;
}


export const fetchDiashow = async (): Promise<Diashow> => {
  const response = await fetch(`${config.apiUrl}/drive`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
}