import React, { ReactNode, useEffect, useState } from "react";
import './styles/ScreenTransitioner.sass';


const ScreenTransitioner = ({ children }: {children: ReactNode[]}) => {
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0)
  let screenTimeout: NodeJS.Timeout | null = null;

  const setUpTimeout = (index: number) => {
    const screensArray = children as ReactNode[];
    const currentChild = screensArray[index];
    if (!currentChild) return;

    // @ts-ignore
    const duration = currentChild.props.duration;

    screenTimeout = setTimeout(() => {
      const nextIndex = (index + 1) % screensArray.length;
      setCurrentScreenIndex(nextIndex);
    }, duration);
  };

  const clearTimeoutTransition = () => {
    if (screenTimeout) {
      clearTimeout(screenTimeout);
    }
  };

  useEffect(() => {
    setUpTimeout(currentScreenIndex);

    return () => {
      clearTimeoutTransition();
    };
  }, [currentScreenIndex]);

  const renderScreens = () => {
    const screens = children.map((child, index) => {
      const isActive = index === currentScreenIndex;
      let screenClass = isActive ? 'Screen ActiveScreen' : 'Screen';


      // Clone the child component with the new prop
      // @ts-ignore
      const childWithActiveProp = React.cloneElement(child, {
        isActive: isActive,
      });

      return (
        <li className={screenClass} key={`screen-${index}`}>
          {childWithActiveProp}
        </li>
      );
    });
    return screens;
  }

  return (
    <div className="ScreenTransitioner">{renderScreens()}</div>
  )
}

export default ScreenTransitioner;