import moment from "moment";
import BookingsScreen from "../BookingScreen/BookingsScreen";
import {
  useCalenderBookingsQuery,
  useDeskBookingsQuery,
} from "../BookingScreen/queries/useBookingsQueries";
import CalendarScreen from "../CalenderScreen/CalendarScreen";
import {
  useCalenderTodayQuery,
  useCalenderTomorrowQuery,
} from "../CalenderScreen/queries/useCalenderQueries";
import DiashowScreen from "../DiashowScreen/DiashowScreen";
import { useDiashowQuery } from "../DiashowScreen/queries/useDiashowQuery";
import ScreenTransitioner from "../ScreenTransitioner/ScreenTransitioner";
import { useVacationQuery } from "../VacationScreen/queries/useVacationQueries";
import VacationsScreen from "../VacationScreen/VacationsScreen";

const christmasTime = (): boolean => {
  const compareDate = moment();
  const startDate = moment("01/12", "DD/MM");
  const endDate = moment("27/12", "DD/MM");
  return compareDate.isBetween(startDate, endDate);
};

const DataWrapperScreen: React.FC = () => {
  // const kicktippData = useKicktippQuery();
  const bookingsDeskData = useDeskBookingsQuery();
  const bookingsCalendarData = useCalenderBookingsQuery();
  const vacationsData = useVacationQuery();
  const calendarTodayData = useCalenderTodayQuery();
  const calendarTommorowData = useCalenderTomorrowQuery();
  const diashowData = useDiashowQuery();
  const isChristmasTime = christmasTime();

  if (
    // kicktippData.isLoading ||
    bookingsDeskData.isLoading ||
    bookingsCalendarData.isLoading ||
    vacationsData.isLoading ||
    calendarTodayData.isLoading ||
    calendarTommorowData.isLoading ||
    diashowData.isLoading
  )
    return <div>Loading...</div>;

  if (
    // !kicktippData.data &&
    !bookingsDeskData.data &&
    !bookingsCalendarData.data &&
    !vacationsData.data &&
    !calendarTodayData.data &&
    !calendarTommorowData.data &&
    !diashowData.data
  )
    return <div>Loading...</div>;

  return (
    <ScreenTransitioner>
      <CalendarScreen duration={8000} christmasTime={isChristmasTime} />
      <BookingsScreen
        duration={25000}
        christmasTime={isChristmasTime}
        beerOclock={false}
      />
      <VacationsScreen duration={8000} christmasTime={isChristmasTime} />
      {/* <KicktippGameDayScreen duration={20000} christmasTime={isChristmasTime} />
      <KicktippBonusScreen duration={20000} christmasTime={isChristmasTime} />
      <KicktippTotalScreen duration={20000} christmasTime={isChristmasTime} />
      <KicktippInviteScreen duration={25000} christmasTime={isChristmasTime} /> */}
      <DiashowScreen duration={50000} christmasTime={isChristmasTime} />
    </ScreenTransitioner>
  );
};

export default DataWrapperScreen;
