import moment from "moment";
import { useEffect, useMemo } from "react";
import { ScreenProps } from "../../App";
import Office from "./Office";
import {
  useCalenderBookingsQuery,
  useDeskBookingsQuery,
} from "./queries/useBookingsQueries";
import "./styles/BookingsScreen.sass";

const upperRooms = [
  {
    name: "Bermuda Office-2-Main Room (16)",
    x: 171,
    y: 1308,
    width: 360,
    height: 253,
    color: "#DA344D",
  },
  {
    name: "Bermuda Office-2-Kitchen (30)",
    x: 14,
    y: 824,
    width: 515,
    height: 476,
    color: "#D84797",
  },
  {
    name: "Bermuda Office-2-Corner Room (5)",
    x: 884,
    y: 1308,
    width: 301,
    height: 253,
    color: "#1BE7FF",
  },
  {
    name: "Bermuda Office-2-Wood Room (6)",
    x: 710,
    y: 276,
    width: 210,
    height: 220,
    color: "#00FFCE",
  },
  {
    name: "Bermuda Office-2-Tiny Room (4)",
    x: 710,
    y: 496,
    width: 210,
    height: 210,
    color: "#00FFCE",
  },
  {
    name: "Bermuda Office-2-Third Room (10)",
    x: 710,
    y: 14,
    width: 210,
    height: 250,
    color: "#F5D547",
  },
];

const lowerRooms = [
  {
    name: "Bermuda Office-1-Cyber Main Room (16)",
    x: 643,
    y: 1308,
    width: 261,
    height: 255,
    color: "#D84797",
  },
  {
    name: "Bermuda Office-1-Audio Room (2)",
    x: 542,
    y: 1308,
    width: 90,
    height: 255,
    color: "#F5D547",
  },
  {
    name: "Bermuda Office-1-Progress Bar (16)",
    x: 15,
    y: 840,
    width: 330,
    height: 275,
    color: "#1BE7FF",
  },
];

interface BookingsProps {
  beerOclock: boolean;
}

const svg_first = document.createElementNS("http://www.w3.org/2000/svg", "svg");
svg_first.setAttribute("viewBox", "0 0 1970 2580");

// Create SVG element for second floor
const svg_second = svg_first.cloneNode(true) as SVGSVGElement;

const BookingsScreen: React.FC<ScreenProps & BookingsProps> = ({
  duration,
  christmasTime,
  beerOclock,
  isActive,
}) => {
  const bookings = useCalenderBookingsQuery();
  const deskBookings = useDeskBookingsQuery();

  useEffect(() => {
    if (!isActive) {
      bookings.refetch();
      deskBookings.refetch();
    }
  }, [isActive]);

  const setupDesks = useMemo(() => {
    if (
      !deskBookings.data ||
      !deskBookings.data.desks ||
      !deskBookings.data.desks_status
    )
      return;

    svg_first.innerHTML = "";
    svg_second.innerHTML = "";
    deskBookings.data.desks.forEach((desk) => {
      if (desk.svg_path === null) return;
      const path = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      let state_color = deskBookings.data.desks_status[desk.id].free
        ? "deskAvailable"
        : "deskBooked";
      let attributes = {
        d: desk.svg_path,
        class: state_color,
        "data-room-id": desk.id,
        "data-floor": desk.floor,
      };
      Object.keys(attributes).forEach((key) =>
        // @ts-ignore
        path.setAttribute(key, attributes[key])
      );
      if (desk.floor === "first") svg_first.appendChild(path);
      else if (desk.floor === "second") svg_second.appendChild(path);
    });

    return { first: svg_first, second: svg_second };
  }, [deskBookings.data?.desks, deskBookings.data?.desks_status]);

  if (bookings.isLoading || deskBookings.isLoading)
    return <div>Loading...</div>;
  if (bookings.isError || deskBookings.isError) return <div>Error...</div>;

  if (
    !bookings.data ||
    !deskBookings.data ||
    !deskBookings.data.desks ||
    !deskBookings.data.desks_status
  )
    return <div>Error...</div>;

  return (
    <div className="office">
      <div className="h1">
        {christmasTime && <img src="../../images/Santa_hat.svg" />}
        <h1>
          Office<span className="Summary">Plan</span>
        </h1>
        <h2>{moment().format("H:mm")}</h2>
      </div>
      <Office
        upperRooms={upperRooms}
        lowerRooms={lowerRooms}
        beerOclock={beerOclock}
        date={new Date()}
        bookings={bookings.data}
        deskBookings={setupDesks}
        christmasTime={christmasTime}
      />
    </div>
  );
};

export default BookingsScreen;
