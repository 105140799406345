import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.sass";
import DataWrapperScreen from "./components/DataWrapper/DataWrapperScreen";
import "./reset.css";

const queryClient = new QueryClient();

export interface ScreenProps {
  duration: number;
  christmasTime: boolean;
  isActive?: boolean;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <DataWrapperScreen />
    </QueryClientProvider>
  );
}

export default App;
