import config from '../config';


export type CalendarItem = {
  summary: string;
  start_date: string;
  end_date: string;
  all_day: boolean;
  location: string;
}



export const fetchCalenderToday= async (): Promise<CalendarItem[]> => {
  const response = await fetch(`${config.apiUrl}/calendar/today`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
}

export const fetchCalenderTomorrow= async (): Promise<CalendarItem[]> => {
  const response = await fetch(`${config.apiUrl}/calendar/tomorrow`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
}