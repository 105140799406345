import moment from "moment";
import { useEffect } from "react";
import { ScreenProps } from "../../App";
import { useVacationQuery } from "./queries/useVacationQueries";
import "./styles/Vacations.sass";

interface VacationProps {
  isActive?: boolean;
}

const VacationsScreen: React.FC<ScreenProps & VacationProps> = ({
  duration,
  christmasTime,
  isActive,
}) => {
  const vacationData = useVacationQuery();

  useEffect(() => {
    if (!isActive) {
      vacationData.refetch();
    }
  }, [isActive]);

  if (vacationData.isLoading) return <div>Loading...</div>;
  if (vacationData.isError || !vacationData.data) return <div>Error...</div>;

  const renderReason = (reason: string) => {
    let icon;

    switch (reason) {
      case "Homeoffice":
        icon = "homeoffice";
        break;
      case "Bezahlter Urlaub DE":
      case "Bezahlter Urlaub (paid leave)":
      case "Überstundenkompensation":
      case "Sonderurlaub bezahlt":
      case "Sonderurlaub unbezahlt":
        icon = "vacation";
        break;
      case "Conference":
        icon = "conference";
        break;
      case "Elternzeit":
      case "Mutterschutz":
        icon = "elternzeit";
        break;
      case "Other":
      case "unbezahlte Abwesenheit":
        icon = "other";
        break;
      case "Überstundenkompensation":
        icon = "vacation";
        break;
      case "Krankheit":
      case "Sick Leave":
      case "Kinderkrankentage":
        icon = "sick";
        break;
      default:
        icon = null;
    }

    if (icon) {
      return (
        <img className="Reason" src={require(`../../images/${icon}.svg`)} />
      );
    }
  };

  const renderPersons = (day: any) => {
    return (
      <div className="People">
        {day.entries.map((user: any, index: any) => {
          return (
            <div key={index} className="Person">
              {renderReason(user["reason"])}
              <span className="Name">{user["userName"]}</span>
              <small className="Till">
                {!user["morning"] && !user["afternoon"] && (
                  <span>till {moment(user["endDate"]).format("MMM Do")}</span>
                )}
                {!user["morning"] && user["afternoon"] && (
                  <span>absent in the afternoon</span>
                )}
                {user["morning"] && !user["afternoon"] && (
                  <span>absent in the morning</span>
                )}
              </small>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="Vacations">
      <div className="Content">
        <div className="h1">
          {christmasTime && <img src="../../images/Santa_hat.svg" />}
          <h1>
            Out of <span className="Summary">Office</span>
          </h1>
        </div>
        <ul
          className={
            vacationData.data &&
            vacationData.data.start &&
            vacationData.data.start.entries.length > 9
              ? "HideSecond"
              : ""
          }
        >
          {Object.keys(vacationData.data).map((date, key) => (
            <li key={key}>
              <div className="Date">
                <span className="Day">
                  {/* @ts-ignore */}
                  {moment(vacationData.data[date].date).format("D")}
                </span>
                <span>
                  {/* @ts-ignore */}
                  {moment(vacationData.data[date].date).format("MMM")}
                </span>
              </div>
              {/* @ts-ignore */}
              {renderPersons(vacationData.data[date])}
            </li>
          ))}
        </ul>
        <div className="Legend">
          <div className="LegendInfo">
            <div className={["Dot", "Green"].join(" ")} />
            reachable
          </div>
          <div className="LegendInfo">
            <div className="Dot" />
            not reachable
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacationsScreen;
