import _ from "lodash";
import moment from "moment";
import { ReactNode } from "react";
import { CalendarBookings } from "../../api/fetchBooking";
import "./styles/BookingsEntry.sass";


interface BookingsEntryProps {
  rooms: any[];
  upper: boolean;
  bookings: CalendarBookings[];
  time: number;
}

const BookingsEntry: React.FC<BookingsEntryProps> = ({ rooms, upper, bookings, time }) => {
  const renderList = (bookings: CalendarBookings[]): ReactNode => {
    const titleLength = 80;
    const roomNames = rooms.map((r) => r.name);

    let filteredBookings = bookings.filter((current: any) => {
      if (current.location.includes(",")) {
        return current.location.split(",").some((floor: any) => {
          return roomNames.includes(floor.trim());
        });
      } else {
        return roomNames.includes(current.location);
      }
    });


    // @ts-ignore
    filteredBookings = _.uniqBy(filteredBookings, "id").map((current: any) => {
      const room = rooms.filter(
        (r) => r.name === current.location
      )[0];

      let location =
        current.location &&
        current.location
          .substring(
            current.location.lastIndexOf("-") + 1,
            current.location.lastIndexOf("(")
          )
          .trim();

      let title = "---";
      if (current.summary) {
        title =
          current.summary.length > titleLength
            ? current.summary.substring(0, titleLength) + "..."
            : current.summary;
      }

      if (current.location.match(/\([0-9]{1,2}\)/g).length > 1) {
        location = "Multiple rooms";
      }

      const hours = moment().diff(moment(current.end_date), "hours");
      const over = moment().isAfter(current.end_date);
      const active = moment().isBetween(current.start_date, current.end_date);
      const durationSeconds = moment(current.end_date).diff(
        moment(current.start_date),
        "seconds"
      );
      const elapsedSeconds = moment().diff(
        moment(current.start_date),
        "seconds"
      );

      const percentageSeconds = Math.max(
        Math.min((elapsedSeconds / durationSeconds) * 100, 100),
        0
      );

      if (over && hours > 4) {
        return;
      }

      if (!room) {
        return;
      }

      return (
        <ul
          key={`${current.id}-${location}`}
          className="ListItem"
          style={{ opacity: over ? 0.3 : active ? 1 : 0.6 }}
        >
          {active && (
            <div
              className="ListItemMarker"
              style={{ borderTopColor: room.color }}
            />
          )}
          <h2>
            <span className="Summary">{title}</span>
          </h2>
          <li className="Location">{location}</li>
          <li>
            <div className="Time">
              {moment(current.start_date).format("HH:mm")} -{" "}
              {moment(current.end_date).format("HH:mm")}
            </div>
            <div className="Progress">
              <span style={{ width: `${percentageSeconds}%` }}></span>
            </div>
          </li>
        </ul>
      );
    });
    if (filteredBookings.length > 0) {
      // @ts-ignore
      return filteredBookings;
      // return "";
    }
    return (
      <ul
        className="ListItem"
        key={upper ? "upper" : "lower"}
      >
        <li className="Empty">No Bookings</li>
      </ul>
    );
  }

  return (
      <div className="InfoLeft">
        <h2
          className="h2"
          dangerouslySetInnerHTML={{
            __html: `${
                upper ? "2<sup>nd</sup>" : "1<sup>st</sup>"
            } Floor`,
          }}
        />
        {renderList(bookings)}
      </div>
  )
}

export default BookingsEntry;