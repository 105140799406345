import moment from "moment";
import Room from "./Room";

export default function Rooms({ bookings, beerOclock, rooms, date }: any) {
  const current = date.getTime();
  return rooms.map((room: any) => {
    let busy = room.name === "Bermuda Office-1-Progress Bar (16)" && beerOclock;

    if (!busy) {
      bookings.forEach((booking: any) => {
        if (booking.location === room.name) {
          if (
            moment(booking.start_date).valueOf() <= current &&
            current <= moment(booking.end_date).valueOf()
          ) {
            busy = true;
          }
        }
      });
    }

    return <Room key={room.name} room={room} busy={busy} />;
  });
}
