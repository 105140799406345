import moment from "moment";
import { CalendarItem } from "../../api/fetchCalender";
import "./styles/Calendar.sass";

interface CalendarEntryProps {
  event: CalendarItem;
  future: boolean;
  index: number;
  christmasTime: boolean;
}

const CalendarEntryComponent: React.FC<CalendarEntryProps> = ({ event, future, index, christmasTime}) => {
  const renderEndTime = () => {
    if (event.all_day) {
      return "All day"
    } else {
      const endAt = moment(event.end_date).format('HH:mm')
      return `Ends ${endAt}`
    }
  }

    window.moment = moment
    const format = 'HH:mm'
    const time = moment(moment.now()).unix()
    const startAt = event.all_day ? <span>&nbsp;</span> : moment(event.start_date).format(format)
    const endAt = moment(event.end_date).unix()

    const isActive = !future &&
      (
        event.all_day ||
        (time > moment(event.start_date).unix() && time < endAt)
      )

    const mainClass = isActive ? 'Entry' : 'LowEntry'
    const timeClass = isActive ? 'Left Highlight' : 'Left'
    const infoClass = isActive ? 'Center Highlight' : 'Center'

    return (
      <div className={mainClass}>
        <div className={timeClass}> { moment(event.start_date).format('HH:mm') }</div>
        <div className={infoClass}>
          { event.summary }
          <p className="Small">{ renderEndTime() }</p>
        </div>
      </div>
    )

}

export default CalendarEntryComponent;