interface RoomProps {
  room: any;
  busy: boolean;
}

const Room: React.FC<RoomProps> = ({ room, busy }) => {
  const isBusy = busy
    ? { fill: "rgba(218, 52, 77, 0.2)" }
    : { fill: "rgba(0, 255, 206, 0.2)" };

  return (
    <g>
      <rect
        className="room__border"
        width={room.width}
        height={room.height}
        x={room.x}
        y={room.y}
        style={isBusy}
      />
      {busy && (
        <polygon
          points={`
              ${room.x + 5},${room.y + 5}
              ${room.x + 90},${room.y + 5}
              ${room.x + 5},${room.y + 90}`}
          style={{ fill: room.color }}
        />
      )}
    </g>
  );
};

export default Room;
