import { useEffect, useState } from "react";
import { ScreenProps } from "../../App";
import { useDiashowQuery } from "./queries/useDiashowQuery";
import "./styles/Diashow.sass";


function getStyle(num: number) {
  switch (num) {
    case 1:
      return "diashowImage1";
    case 2:
      return "diashowImage2";
    case 3:
      return "diashowImage3";
    default:
      return "diashowImageMore"; // more than 4 images will overflow
  }
}

const DiashowScreen: React.FC<ScreenProps> = ({ duration, christmasTime, isActive }) => {
  const [bigImages, setBigImages] = useState<string[]>([]);
  const [amountBigImages, setAmountBigImages] = useState<number>(3);
  const diashow = useDiashowQuery();

  const getRandomImages = (imageArray: string[]) => {
    const shuffled = [...imageArray].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };


  useEffect(() => {
    if (!isActive) {
      diashow.refetch();
    }
    if (!diashow.data || !diashow.data.images) {
      return;
    }
    let updateDuration = duration / 2;
    const images = diashow.data.images;

    if (isActive) {
      setBigImages(getRandomImages(images));
      setAmountBigImages(Math.min(images.length, 3));


      setTimeout(() => {
        setBigImages(getRandomImages(images));
        setAmountBigImages(Math.min(images.length, 3));
      }, updateDuration);
    }
  }, [isActive, duration, diashow.data]);

  if (diashow.isLoading) return <div>Loading...</div>;

  if (diashow.isError || !diashow.data) return <div>Error...</div>;

  return (
    <div className="diashowScreen">
      <div className="header">
        {christmasTime && <img src="../../images/Santa_hat.svg" />}
        <div className="h2"><h2>#PromptBattle</h2></div>
        <div className="h1"><h1>{diashow.data.headline}</h1></div>
      </div>
      {diashow.data.images && diashow.data.images.length > 0 && (
        <div className="diashowContainer">
          <div className="diashowImagesContainer">
            {bigImages.map((image, key) => {
              return (
                <div key={key}>
                  <img className={getStyle(amountBigImages)} src={image} />
                </div>
              );
            })}
          </div>
          <div className="diashowPreviewContainer">
            {diashow.data.images.map((image, index) => {
              return (
                <div key={index}>
                  <img src={image} className="diashowPreviewImage" />
                </div>
              );
            })}
          </div>
          <div>
            <span className="diashowImageCounter">
              {diashow.data.images.length} images
            </span>
          </div>
        </div>
      )}
      {(!diashow|| diashow.data.images.length) <= 0 && (
        <div className="diashowNoImages">
          <span>
            {"No images yet :( - Loading may take a while, be patient"}
          </span>
        </div>
      )}
    </div>
  );
};

export default DiashowScreen;
