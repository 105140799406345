import { useQuery } from "@tanstack/react-query"
import { fetchCalenderBookings, fetchDeskBooking } from "../../../api/fetchBooking"

export const useCalenderBookingsQuery = () =>
  useQuery({
    queryKey: ['bookings'],
    queryFn: () => fetchCalenderBookings()
  })

  export const useDeskBookingsQuery = () =>
    useQuery({
      queryKey: ['desks'],
      queryFn: () => fetchDeskBooking()
    })
  
  