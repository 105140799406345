import moment from "moment";
import { CalendarBookings } from "../../api/fetchBooking";
import GrundRissOben from "../../images/grundriss-oben.svg";
import GrundRissUnten from "../../images/grundriss-unten.svg";
import BookingsEntry from "./BookingsEntry";
import Room from "./Room";
import "./styles/BookingsScreen.sass";
import Rooms from "./Rooms";

interface OfficeProps {
  upperRooms: any;
  lowerRooms: any;
  beerOclock: boolean;
  date: Date;
  bookings: CalendarBookings[];
  deskBookings?: {
    first: SVGSVGElement;
    second: SVGSVGElement;
  };
  christmasTime: boolean;
}

const Office: React.FC<OfficeProps> = ({
  upperRooms,

  lowerRooms,
  beerOclock,
  date,
  bookings,
  deskBookings,
  christmasTime,
}) => {
  const renderGhost = () => {
    return (
      <symbol id="ghost" viewBox="0 0 210 210">
        <path
          fill="currentColor"
          d="M140 194v-14h-10v14h-14v11H88v-11H70v-11h-9v11H51v11H20v-14H5V80h13V51h14V33h11V17h31V5h50v12h32v14h13v10h19v39h14v111h-16v14h-31v-11z"
          transform="translate(1)"
        />
        <path
          fill="#000"
          d="M140 194h15v11h31v-14h16V80h-14V41h-19V31h-13V17h-32V5H74v12H43v16H32v18H18v29H5v111h15v14h31v-11h10v-11h9v11h18v11h28v-11h14v-14h10v14zm-19 5v11H83v-11H56v11H15v-14H0V75h13V46h14V28h11V12h31V0h60v12h32v14h13v10h19v39h14v121h-16v14h-41v-11h-15v-19l5 5h-10l5-5v19h-14z"
          transform="translate(1)"
        />
        <g id="eye" transform="translate(48 47)">
          <path
            className="pupil"
            id="Path"
            fill="#FFF"
            d="M41 8h17v48H43v11H18V56H0V8h16V0h25z"
          />
          <path id="Rectangle" fill="#010000" d="M31 18h21v31H31z" />
        </g>
        <g id="eye" transform="translate(125 47)">
          <path
            className="pupil"
            id="Path"
            fill="#FFF"
            d="M41 8h17v48H43v11H18V56H0V8h16V0h25z"
          />
          <path id="Rectangle" fill="#010000" d="M31 18h21v31H31z" />
        </g>
      </symbol>
    );
  };

  const renderChristmasGhost = () => {
    return (
      <symbol id="ghost-xmas" viewBox="0 0 290 290">
        <g id="ghost" transform="translate(70 75)">
          <g id="body">
            <path
              id="current"
              fill="currentColor"
              d="M140 194v-14h-10v14h-14v11H88v-11H70v-11h-9v11H51v11H20v-14H5V80h13V51h14V33h11V17h31V5h50v12h32v14h13v10h19v39h14v111h-16v14h-31v-11z"
            />
            <path
              id="Shape"
              fill="#000"
              fillRule="nonzero"
              d="M140 194h15v11h31v-14h16V80h-14V41h-19V31h-13V17h-32V5H74v12H43v16H32v18H18v29H5v111h15v14h31v-11h10v-11h9v11h18v11h28v-11h14v-14h10v14zm-19 5v11H83v-11H56v11H15v-14H0V75h13V46h14V28h11V12h31V0h60v12h32v14h13v10h19v39h14v121h-16v14h-41v-11h-15v-19l5 5h-10l5-5v19h-14z"
            />
          </g>
          <g id="eye" transform="translate(47 47)">
            <path
              id="Path"
              fill="#FFF"
              d="M41 8h17v48H43v11H18V56H0V8h16V0h25z"
            />
            <path id="Rectangle" fill="#010000" d="M31 18h21v31H31z" />
          </g>
          <g id="eye" transform="translate(124 47)">
            <path
              id="Path"
              fill="#FFF"
              d="M41 8h17v48H43v11H18V56H0V8h16V0h25z"
            />
            <path id="Rectangle" fill="#010000" d="M31 18h21v31H31z" />
          </g>
        </g>
        <g id="hat" fillRule="nonzero" transform="translate(5 17)">
          <path
            id="Combined-Shape"
            fill="#FFF"
            d="M98 103v22H70V97h16V75h20V57h36V43h34v28h-28v14h-20v18H98zM22 90v11h13v29H0V90h22z"
          />
          <path
            id="Path-2"
            fill="#DD5A58"
            d="M159 43V27h-18V13h-28V0H73v17H49v17H34v30H22v37h27V88h9V75h53V58h31V43z"
          />
        </g>
      </symbol>
    );
  };

  const renderChristmasGhostAnimation = () => {
    return (
      <g>
        <use
          className="ghost__green"
          xlinkHref="#ghost-xmas"
          width="55"
          height="55"
          transform="translate(-13 -13)"
        >
          <animateMotion dur="36s" repeatCount="indefinite">
            <mpath xlinkHref="#move"></mpath>
          </animateMotion>
        </use>
        <use
          className="ghost__pink"
          xlinkHref="#ghost-xmas"
          width="55"
          height="55"
          transform="translate(-13 -13)"
        >
          <animateMotion dur="36s" repeatCount="indefinite">
            <mpath xlinkHref="#move2"></mpath>
          </animateMotion>
        </use>
      </g>
    );
  };

  const renderChristmasGhostAnimationDown = () => {
    return (
      <g>
        <use
          className="ghost__green"
          xlinkHref="#ghost-xmas"
          width="55"
          height="55"
          transform="translate(-13 -13)"
        >
          <animateMotion dur="28s" repeatCount="indefinite">
            <mpath xlinkHref="#move3"></mpath>
          </animateMotion>
        </use>
        <use
          className="ghost__pink"
          xlinkHref="#ghost-xmas"
          width="55"
          height="55"
          transform="translate(-13 -13)"
        >
          <animateMotion dur="28s" repeatCount="indefinite">
            <mpath xlinkHref="#move4"></mpath>
          </animateMotion>
        </use>
      </g>
    );
  };

  const renderGhostAnimation = () => {
    return (
      <g>
        <use className="ghost__green" xlinkHref="#ghost" width="42" height="42">
          <animateMotion dur="36s" repeatCount="indefinite">
            <mpath xlinkHref="#move"></mpath>
          </animateMotion>
        </use>
        <use className="ghost__pink" xlinkHref="#ghost" width="42" height="42">
          <animateMotion dur="36s" repeatCount="indefinite">
            <mpath xlinkHref="#move2"></mpath>
          </animateMotion>
        </use>
      </g>
    );
  };

  const renderGhostAnimationDown = () => {
    return (
      <g key="ghost">
        <use className="ghost__green" xlinkHref="#ghost" width="42" height="42">
          <animateMotion dur="28s" repeatCount="indefinite">
            <mpath xlinkHref="#move3"></mpath>
          </animateMotion>
        </use>
        <use className="ghost__pink" xlinkHref="#ghost" width="42" height="42">
          <animateMotion dur="28s" repeatCount="indefinite">
            <mpath xlinkHref="#move4"></mpath>
          </animateMotion>
        </use>
      </g>
    );
  };

  return (
    <div className="Office__plan">
      <BookingsEntry
        bookings={bookings}
        upper={true}
        rooms={upperRooms}
        time={date.getTime()}
        key="upper"
      />
      <svg
        viewBox="0 0 1201 1575"
        className="room"
        xmlns="http://www.w3.org/2000/svg"
      >
        {christmasTime && renderChristmasGhost()}
        {!christmasTime && renderGhost()}

        <path
          id="move"
          fill="none"
          stroke="none"
          d="M555 1225v168h240v-168h128v-75h-93v170h306-306V720.81h93V583h-73v54H723V405h200v820H32.05z"
        />
        <path
          id="move2"
          fill="none"
          stroke="none"
          d="M206 858v637h255v-176h87v-90h268v275H548v-273h567V867H923V197.1H725h390V532H922v696H337V858z"
        />
        <Rooms
          bookings={bookings}
          beerOclock={beerOclock}
          rooms={upperRooms}
          date={date}
        />
        <image
          height="100%"
          width="100%"
          // xlinkHref={require("../../images/grundriss-oben.svg")}
          xlinkHref={GrundRissOben}
        >
          <title>Office oben</title>
        </image>
        {christmasTime && renderChristmasGhostAnimation()}
        {!christmasTime && renderGhostAnimation()}
        {deskBookings?.second && (
          <g
            dangerouslySetInnerHTML={{
              __html: deskBookings.second.outerHTML,
            }}
          />
        )}
      </svg>

      <BookingsEntry
        bookings={bookings}
        upper={false}
        rooms={lowerRooms}
        time={date.getTime()}
        key="lower"
      />
      <svg
        viewBox="0 0 1201 1575"
        className="room room-second"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="move3"
          fill="none"
          stroke="none"
          d="M222 765V643h-79v508h317v96.5h657.5V1492H941v-251H293.5v-106H151V765z"
        />
        <path
          id="move4"
          fill="none"
          stroke="none"
          d="M72 1248h961.5v163h-80v-61.5h34V1245H855v-101h218.5v-128H845V894h-69v14.5h65.5V922H777v17h64.5v77H1090v128H734.5v101H72z"
        />
        <Rooms
          bookings={bookings}
          beerOclock={beerOclock}
          rooms={lowerRooms}
          date={date}
        />
        <image
          height="100%"
          width="100%"
          // xlinkHref={require("../../images/grundriss-unten.svg")}
          xlinkHref={GrundRissUnten}
        >
          <title>Office unten</title>
        </image>
        {deskBookings?.first && (
          <g
            dangerouslySetInnerHTML={{
              __html: deskBookings.first.outerHTML,
            }}
          />
        )}
        {christmasTime && renderChristmasGhostAnimationDown()}
        {!christmasTime && renderGhostAnimationDown()}
      </svg>
    </div>
  );
};

export default Office;
