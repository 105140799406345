import { useQuery } from "@tanstack/react-query";
import { fetchCalenderToday, fetchCalenderTomorrow } from "../../../api/fetchCalender";

export const useCalenderTodayQuery = () =>
  useQuery({
    queryKey: ['today'],
    queryFn: () => fetchCalenderToday()
  })

export const useCalenderTomorrowQuery = () =>
  useQuery({
    queryKey: ['tomorrow'],
    queryFn: () => fetchCalenderTomorrow()
  })

