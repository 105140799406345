
const env = process.env.REACT_APP_SECONDSCREEN_ENVIRONMENT || 'development';

export const SECONDSCREEN_DEVELOPMENT = env === 'development' ? true : false;
export const SECONDSCREEN_STAGING = env === 'staging' ? true : false;

export default {
  apiUrl: process.env.REACT_APP_API_URL || '/api/v1',
  apiCorsPolicy: 'include',

  env: {
    environment: env,
    development: SECONDSCREEN_DEVELOPMENT,
    staging: SECONDSCREEN_STAGING,
    devOrStaging: SECONDSCREEN_DEVELOPMENT || SECONDSCREEN_STAGING ? true : false
  },
}