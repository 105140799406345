import config from "../config";


export type CalendarBookings = {
  id: number;
  summary: string;
  start_date: string;
  end_date: string;
  all_day: boolean;
  location: string;
}

export type DeskBooking = {
  desks: {
    id: number;
    floor: string;
    svg_path: string;
    created_at: string;
    updated_at: string;
  }[];
  desks_status: any;

}

export const fetchCalenderBookings = async (): Promise<CalendarBookings[]> => {
  const response = await fetch(`${config.apiUrl}/calendar/bookings`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
}

export const fetchDeskBooking = async (): Promise<DeskBooking> => {
  const response = await fetch(`${config.apiUrl}/desk_bookings`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
}