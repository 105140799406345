import config from "../config";

type Vacation = {
  start: {
    date: string;
    entries: {
      userName?: string;
      startDate?: string;
      endDate?: string;
      reason?: string;
      morning?: boolean;
      afternoon?: boolean;
    }[]
  }

  end: {
    date: string;
    entries: {
      userName?: string;
      startDate?: string;
      endDate?: string;
      reason?: string;
      morning?: boolean;
      afternoon?: boolean;
    }[]
  }
}


export const fetchVacation = async (): Promise<Vacation> => {
  const response = await fetch(`${config.apiUrl}/vacations`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data;
}